import React, { useState, useEffect, useRef } from 'react';
import './verifyPdf.scss';
import logo from '../../img/EarthID Logo - Primary - BLACK.png'
import cross from '../../img/redCross2.png'
import Swal from 'sweetalert2';
import { useParams } from "react-router-dom";




const VerifyPdfFail = () => {
    // const [responseData, setResponseData] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isVerificationLoading, setIsVerificationLoading] = useState([true, true, true, true, true]);
    const [verificationData, setVerificationData] = useState([]);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [isVerifying, setIsVerifying] = useState(false);
    //const [showJson, setShowJson] = useState(true);
    //const { userName, issuer, certType, docId } = useParams();
    

    const responseData = {
        IssuingBank: "EarthID",
        AccountOwner: "Mr. John Doe",
        StatementDate: "22/10/2014 - 21/12/2014",
        StatementID: "6698e49e51e458ceb2005035",
        DigitalSignature: "Not Verified",
        TamperEvidence: "Alterations Detected",
        QRCodeVerificationStatus: "Failed",
        Reason: "Digital Signature Mismatch (Tampered or altered statement)."
    }


    useEffect(() => {
        handlePopupOpen();
    }, []);


    useEffect(() => {
        if (isButtonClicked) {
            //   setIsLoading(true);
            setIsVerificationLoading([true, true, true, true, true, true, true, true, true]);
            setVerificationData([]);

            setTimeout(() => {
                //setIsLoading(false);
                setIsVerificationLoading([false, true, true, true, true, true, true, true, true]);
                setVerificationData([
                    responseData.IssuingBank,
                    responseData.AccountOwner,
                    responseData.StatementDate,
                    responseData.StatementID,
                    responseData.DigitalSignature,
                    responseData.TamperEvidence,
                    responseData.QRCodeVerificationStatus,
                    responseData.Reason
                ]);

                setTimeout(() => {
                    //setIsLoading(false);
                    setIsVerificationLoading([false, false, true, true, true, true, true, true, true]);
                    setVerificationData([
                        responseData.IssuingBank,
                        responseData.AccountOwner,
                        responseData.StatementDate,
                        responseData.StatementID,
                        responseData.DigitalSignature,
                        responseData.TamperEvidence,
                        responseData.QRCodeVerificationStatus,
                        responseData.Reason
                    ]);

                    setTimeout(() => {
                        //setIsLoading(false);
                        setIsVerificationLoading([false, false, false, true, true, true, true, true, true]);
                        setVerificationData([
                            responseData.IssuingBank,
                            responseData.AccountOwner,
                            responseData.StatementDate,
                            responseData.StatementID,
                            responseData.DigitalSignature,
                            responseData.TamperEvidence,
                            responseData.QRCodeVerificationStatus,
                            responseData.Reason
                        ]);

                        setTimeout(() => {
                            //setIsLoading(false);
                            setIsVerificationLoading([false, false, false, false, true, true, true, true, true]);
                            setVerificationData([
                                responseData.IssuingBank,
                                responseData.AccountOwner,
                                responseData.StatementDate,
                                responseData.StatementID,
                                responseData.DigitalSignature,
                                responseData.TamperEvidence,
                                responseData.QRCodeVerificationStatus,
                                responseData.Reason
                            ]);

                            setTimeout(() => {
                                //setIsLoading(false);
                                setIsVerificationLoading([false, false, false, false, false, true, true, true, true]);
                                setVerificationData([
                                    responseData.IssuingBank,
                                    responseData.AccountOwner,
                                    responseData.StatementDate,
                                    responseData.StatementID,
                                    responseData.DigitalSignature,
                                    responseData.TamperEvidence,
                                    responseData.QRCodeVerificationStatus,
                                    responseData.Reason
                                ]);

                                setTimeout(() => {
                                    //setIsLoading(false);
                                    setIsVerificationLoading([false, false, false, false, false, false, true, true, true]);
                                    setVerificationData([
                                        responseData.IssuingBank,
                                        responseData.AccountOwner,
                                        responseData.StatementDate,
                                        responseData.StatementID,
                                        responseData.DigitalSignature,
                                        responseData.TamperEvidence,
                                        responseData.QRCodeVerificationStatus,
                                        responseData.Reason
                                    ]);

                                    setTimeout(() => {
                                        //setIsLoading(false);
                                        setIsVerificationLoading([false, false, false, false, false, false, false, true, true]);
                                        setVerificationData([
                                            responseData.IssuingBank,
                                            responseData.AccountOwner,
                                            responseData.StatementDate,
                                            responseData.StatementID,
                                            responseData.DigitalSignature,
                                            responseData.TamperEvidence,
                                            responseData.QRCodeVerificationStatus,
                                            responseData.Reason
                                        ]);

                                        setTimeout(() => {
                                            //setIsLoading(false);
                                            setIsVerificationLoading([false, false, false, false, false, false, false, false, true]);
                                            setVerificationData([
                                                responseData.IssuingBank,
                                                responseData.AccountOwner,
                                                responseData.StatementDate,
                                                responseData.StatementID,
                                                responseData.DigitalSignature,
                                                responseData.TamperEvidence,
                                                responseData.QRCodeVerificationStatus,
                                                responseData.Reason
                                            ]);

                                            setTimeout(() => {
                                                //setIsLoading(false);
                                                setIsVerificationLoading([false, false, false, false, false, false, false, false, false]);
                                                setVerificationData([
                                                    responseData.IssuingBank,
                                                    responseData.AccountOwner,
                                                    responseData.StatementDate,
                                                    responseData.StatementID,
                                                    responseData.DigitalSignature,
                                                    responseData.TamperEvidence,
                                                    responseData.QRCodeVerificationStatus,
                                                    responseData.Reason
                                                ]);
                                            }, 1000);
                                        }, 1000);
                                    }, 1000);
                                }, 1000);
                            }, 1000);
                        }, 1000);
                    }, 1000);
                }, 1000);
            }, 3000);
        }
    }, [isButtonClicked]);








    //Verify Api

    const handlePopupOpen = () => {
        setIsPopupOpen(true);
        setIsButtonClicked(true);
        setIsVerifying(true);

        setTimeout(() => {
            setIsVerifying(false);
        }, 3000); // Delay of 3 seconds

    };

    const handlePopupClose = () => {
        setIsPopupOpen(false);
    };



    const handleHelpClick = () => {
        Swal.fire({
            title: 'Help',
            html: `
            <div style="text-align: left;">
             
              <p>For any assistance, contact support at support@myearth.id</p>
            </div>
          `,
            icon: 'info',
            showCloseButton: true,
            showConfirmButton: false,
            customClass: {
                popup: 'swal-custom-popup',
                title: 'swal-custom-title',
                closeButton: 'swal-custom-close-button'
            }
        });
    };




    return (
        <>

            <div className="page-container">
                <nav className="navbar-cert">
                    <div className="logo">
                        <img src={logo}></img>
                    </div>
                    <ul className="nav-menu">
                        <li className="nav-item"><a onClick={handleHelpClick}>Help</a></li>
                    </ul>
                </nav>
                {/* <button className="verify-btn" onClick={handlePopupOpen}>
                    Verify
                </button> */}






                {isPopupOpen && (
                    <div className="popup-container">
                        {isVerifying ? (
                            <div>
                                <div className="spinner"></div>
                                <div className="spinner-text">Verifying Statement...</div>
                            </div>
                        ) : (
                            <div className="popup">
                                <div className="popup-header">
                                    <div className="popup-title">Verification Details</div>
                                    <button className="popup-close" onClick={handlePopupClose}>
                                        X
                                    </button>
                                </div>
                                <hr></hr>
                                <div className="popup-body">
                                    {isVerificationLoading[0] ? (
                                        <div>
                                            <div className="verification-item">
                                                <div className="verification-result">
                                                    <div className="spinner1"></div>
                                                </div>
                                                <div className="verification-label">Verifying Issuing Bank...</div>
                                            </div>
                                            <hr></hr>
                                        </div>

                                    ) : (
                                        <div>
                                            <div className="verification-item">
                                                <div className="verification-result">
                                                    <div className="success">&#10004;</div>
                                                </div>
                                                {/* <div className="verification-label">Issuer is Valid:</div> */}

                                                <div className="verification-result1">
                                                <div>
        <strong>Issuing Bank:</strong> {verificationData[0]}
    </div>

                                                </div>
                                            </div>
                                            <hr></hr>
                                        </div>

                                    )}
                                    {isVerificationLoading[1] ? (
                                        <div>
                                            <div className="verification-item">
                                                <div className="verification-result">
                                                    <div className="spinner1"></div>
                                                </div>
                                                <div className="verification-label">Verifying Account Owner...</div>
                                            </div>
                                            <hr></hr>
                                        </div>

                                    ) : (
                                        <div>
                                            <div className="verification-item">
                                                <div className="verification-result">
                                                    <div className="success">&#10004;</div>
                                                </div>
                                                {/* <div className="verification-label">Issued by:</div> */}

                                                <div className="verification-result1">
                                                    {/* {verificationData[1] && <div>{verificationData[1]}</div>} */}
                                                    <div><strong>Account Owner: </strong>{verificationData[1]}</div>

                                                </div>
                                            </div>
                                            <hr></hr>
                                        </div>

                                    )}
                                    {isVerificationLoading[2] ? (
                                        <div>
                                            <div className="verification-item">
                                                <div className="verification-result">
                                                    <div className="spinner1"></div>
                                                </div>
                                                <div className="verification-label">Verifying Statement Date...</div>
                                            </div>
                                            <hr></hr>
                                        </div>

                                    ) : (
                                        <div>
                                            <div className="verification-item">
                                                <div className="verification-result">
                                                    <div className="success">&#10004;</div>
                                                </div>
                                                {/* <div className="verification-label">Issued by:</div> */}

                                                <div className="verification-result1">
                                                    {/* {verificationData[1] && <div>{verificationData[1]}</div>} */}
                                                    <div><strong>Statement Date: </strong>{verificationData[2]}</div>

                                                </div>
                                            </div>
                                            <hr></hr>
                                        </div>

                                    )}
                                    {isVerificationLoading[3] ? (
                                        <div>
                                            <div className="verification-item">
                                                <div className="verification-result">
                                                    <div className="spinner1"></div>
                                                </div>
                                                <div className="verification-label">Checking Statement ID...</div>
                                            </div>
                                            <hr></hr>
                                        </div>

                                    ) : (
                                        <div>
                                           <div className="verification-item">
                                                <div className="verification-result">
                                                    <div className="success">&#10004;</div>
                                                </div>
                                                {/* <div className="verification-label">Issued by:</div> */}

                                                <div className="verification-result1">
                                                    {/* {verificationData[1] && <div>{verificationData[1]}</div>} */}
                                                    <div><strong>Statement ID: </strong>{verificationData[3]}</div>

                                                </div>
                                            </div>
                                            <hr></hr>
                                        </div>

                                    )}
                                     {isVerificationLoading[4] ? (
                                        <div>
                                            <div className="verification-item">
                                                <div className="verification-result">
                                                    <div className="spinner1"></div>
                                                </div>
                                                <div className="verification-label">Checking Statement Status...</div>
                                            </div>
                                            <hr></hr>
                                        </div>

                                    ) : (
                                        <div>
                                            <div className="verification-item">
                                                <div className="verification-result">
                                                <div className="failure">&#10008;</div>
                                                </div>
                                                {/* <div className="verification-label">Issued by:</div> */}

                                                <div className="verification-result1">
                                                    {/* {verificationData[1] && <div>{verificationData[1]}</div>} */}
                                                    <div><strong>Statement Status: </strong>{verificationData[4]}</div>

                                                </div>
                                            </div>
                                            <hr></hr>
                                        </div>

                                    )}
                                     {isVerificationLoading[5] ? (
                                        <div>
                                            <div className="verification-item">
                                                <div className="verification-result">
                                                    <div className="spinner1"></div>
                                                </div>
                                                <div className="verification-label">Checking Tamper Evidence...</div>
                                            </div>
                                            <hr></hr>
                                        </div>

                                    ) : (
                                        <div>
                                           <div className="verification-item">
                                                <div className="verification-result">
                                                <div className="failure">&#10008;</div>
                                                </div>
                                                {/* <div className="verification-label">Issued by:</div> */}

                                                <div className="verification-result1">
                                                    {/* {verificationData[1] && <div>{verificationData[1]}</div>} */}
                                                    <div><strong>Tamper Evidence: </strong>{verificationData[5]}</div>

                                                </div>
                                            </div>
                                            <hr></hr>
                                        </div>

                                    )}
                                     {isVerificationLoading[6] ? (
                                        <div>
                                            <div className="verification-item">
                                                <div className="verification-result">
                                                    <div className="spinner1"></div>
                                                </div>
                                                <div className="verification-label">Checking QR Code Verification Status...</div>
                                            </div>
                                            <hr></hr>
                                        </div>

                                    ) : (
                                        <div>
                                            <div className="verification-item">
                                                <div className="verification-result">
                                                <div className="failure">&#10008;</div>
                                                </div>
                                                {/* <div className="verification-label">Issued by:</div> */}

                                                <div className="verification-result1">
                                                    {/* {verificationData[1] && <div>{verificationData[1]}</div>} */}
                                                    <div><strong>QR Code Verification Status: </strong>{verificationData[6]}</div>

                                                </div>
                                            </div>
                                            <hr></hr>
                                        </div>

                                    )}
                                     {isVerificationLoading[7] ? (
                                        <div>
                                            <div className="verification-item">
                                                <div className="verification-result">
                                                    <div className="spinner1"></div>
                                                </div>
                                                <div className="verification-label">Verification data...</div>
                                            </div>
                                            <hr></hr>
                                        </div>

                                    ) : (
                                        <div>
                                            <div className="verification-item">
                                                <div className="verification-result">
                                                <div className="failure">&#10008;</div>
                                                </div>
                                                {/* <div className="verification-label">Issued by:</div> */}

                                                <div className="verification-result1">
                                                    {/* {verificationData[1] && <div>{verificationData[1]}</div>} */}
                                                    <div><strong>Reason: </strong>{verificationData[7]}</div>

                                                </div>
                                            </div>
                                            <hr></hr>
                                        </div>

                                    )}
                                    {isVerificationLoading[8] ? (
                                        <div>
                                            <div className="verification-item">
                                                <div className="verification-result">
                                                    <div className="spinner1"></div>
                                                </div>
                                                <div className="verification-label">Verifying Statement Data...</div>
                                            </div>
                                            <hr></hr>
                                        </div>

                                    ) : (
                                        <div>
                                            <div className="verification-item">
                                                <div className="verification-result">
                                                    <div className="success1"><img src={cross}></img></div>
                                                </div>
                                                <div className="verification-label"></div>

                                                <div className="verification-result11">
                                                    <div className='verified'>VERIFICATION FAILED</div>

                                                    <div className='verified1'>For further assistance please contact support@myearth.id</div>


                                                </div>
                                            </div>
                                            <hr></hr>
                                        </div>

                                    )}

                                </div>
                            </div>
                        )}
                    </div>
                )}

                <footer className="footerr">
                    <div className="footerr-text">
                        © 2025 EarthID, Inc. All Rights Reserved.
                    </div>
                </footer>
            </div>

        </>

    );
};

export default VerifyPdfFail;